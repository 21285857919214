<template>
  <div class="home">
    <hero-panel size="lg" video name="home" wave slim no-cols><!--section class="hero hero-home hero-lg"-->
            <b-col offset-lg="7" lg="5">
                <h1 class="display-3 text-lg-right">
                    Carefully <strong>curated</strong> cuisine
                </h1>
                <div class="text-lg-right mt-5">
                    <b-btn size="lg" variant="outline-light" pill class="btn-double mr-3 px-4 mb-3" :to="{name:'brands'}">Meet our brands</b-btn>
                    <b-btn size="lg" variant="light" pill class="btn-double px-4 mb-3" :to="{name:'order'}">Order now</b-btn>
                </div>
            </b-col>
        
    </hero-panel>
    <content-panel class="bg-light-blue-50">
      <template #base>
        <img-wrapper right>
          <img src="@/assets/img/photos/paper-bag.jpg" alt="Photo of paper bag with Fresco logo."/>          
        </img-wrapper>
      </template>
      <b-col lg="6" order-lg="2" class="">
      </b-col>
      <b-col lg="5" order-lg="1" xl="6">
        <h2 class="title">We believe that <strong>ordering take away</strong> shouldn't have to mean <strong>sacrificing quality</strong> or <strong>authenticity</strong>.</h2>
        <p class="mb-5">This belief forms the basis of our guiding principles when curating our brands and crafting menus for customers to enjoy in the comfort of their homes.</p>
        <div>
          <b-btn size="lg" class="btn-double mr-4 mb-3" pill variant="outline-secondary" :to="{name:'about'}">Find out more</b-btn>
          <b-btn size="lg" class="btn-double mb-3" :to="{name: 'order'}" pill>Order now</b-btn>
        </div>
      </b-col>
      
    </content-panel>
    <content-panel>
      <img-wrapper left>
        <img src="/img/menu_items/burger_cheese.jpg" alt="Photo of paper bag with Fresco logo."/>
      </img-wrapper>
      <b-col lg="5" offset-lg="7" xl="6" offset-xl="6">
        <h2 class="title">Food for every <strong>fancy</strong>,<br />
        menus for every <strong>mood</strong>.</h2>
        <p class="mb-2">Our expert chefs develop wholesome, delicious meals to suit every taste and preference, using high quality, fresh and responsibly sourced ingredients.</p>
        <b-row class="brand-logos">
        <b-col cols="5">
          <router-link :to="{name:'lasagnista'}" title="Il Lasagnista">
          <img src="@/assets/img/brands/lasagnista-900x600.png" alt="Il Lasagnista">
          </router-link>
        </b-col>
        <b-col cols="5">
          <router-link :to="{name:'alt-slice'}" title="The Alt Slice">
          <img src="@/assets/img/brands/alt-slice-900x600.png" alt="The Alt Slice">
          </router-link>
        </b-col>
      </b-row>
      <b-row class="brand-logos ">
        <b-col cols="5">
          <router-link :to="{name:'explorers-grill'}" title="Explorer's Grill">
          <img src="@/assets/img/brands/explorers-grill-900x600.png" alt="Explorer's Grill">
          </router-link>
        </b-col>
        <b-col cols="5">
          <router-link :to="{name:'wingcognito'}" title="Wingcognito">
          <img src="@/assets/img/brands/wingcognito-900x600.png" alt="Wingcognito">
          </router-link>
        </b-col>
      </b-row>
        <b-btn size="lg" pill class="btn-double mt-4" variant="outline-secondary" :to="{name:'brands'}">Meet our brands</b-btn>
      </b-col>
    </content-panel>
    <content-panel class="bg-danger text-white text-center">
      <b-col cols="12">
        <h2 class="title">Order on your <strong>favourite</strong> app,<br />earn rewards on <strong>ours</strong>.</h2>
        <p>We work with leading delivery apps covering your area including <strong>Deliveroo</strong>, <strong>Uber Eats</strong> and <strong>Just Eat</strong> to make sure you can enjoy our food at home without any fuss.
        Regardless of how you order, you'll soon be able to collect loyalty points and receive special offers and exclusive menu items with our app.</p>
        <div class="m-4">
          <b-btn class="btn-provider mx-3 mb-3 btn-double" variant="light" :to="{name: 'order'}" >
            
              <img src="@/assets/img/deliveroo-logo.svg" class="py-1" alt="Deliveroo logo"/>
            
          </b-btn>
          <b-btn variant="light" href="#" class="btn-provider mx-3 mb-3 btn-double" :to="{name: 'order'}">
            <img src="@/assets/img/ubereats-logo.svg" class="py-3" alt="Uber Eats logo"/>
          </b-btn>
          <b-btn variant="light" href="#" class="btn-provider mx-3 mb-3 btn-double" :to="{name: 'order'}">
            <img src="@/assets/img/justeat-logo.svg" class="py-1" alt="Just Eat logo"/>
          </b-btn>
        </div>
      </b-col>
    
    </content-panel>
  </div>
</template>

<script>
import HeroPanel from '@/components/HeroPanel'
import ContentPanel from '@/components/ContentPanel'
import ImgWrapper from '@/components/ImgWrapper'
export default {
  name: 'HomePage',
  components: {
    HeroPanel,
    ContentPanel,
    ImgWrapper
  }
}
</script>
<style lang="scss">
.btn.btn-provider{
  width: 10rem;
  height: 8rem;  //128px = 8rem - 1rem for y padding
  
  img {
    max-width: 100%;
    max-height: 100%;
  }
}
</style>