import { library } from "@fortawesome/fontawesome-svg-core"

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

import {
  faFacebook,
  faInstagram,
  faTiktok,
  faTwitter,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons"
library.add(faFacebook, faInstagram, faTwitter, faTiktok,faWhatsapp)

import {
  faPhone,
  faEnvelope,
  faCircleQuestion,
  faQuestion,
  faFilePdf,
  faXmarkCircle
} from "@fortawesome/free-solid-svg-icons"
library.add(faPhone, faEnvelope, faCircleQuestion, faQuestion, faFilePdf, faXmarkCircle)

//import {
//  farCircleQuestion,
//} from "@fortawesome/free-regular-svg-icons"
//library.add(farCircleQuestion)


export default {
  install(Vue) {
    Vue.component("FontAwesomeIcon", FontAwesomeIcon)
  },
}
