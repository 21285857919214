<template>
    <b-collapse id="side-nav" is-nav>
        <b-navbar-toggle target="side-nav"></b-navbar-toggle>
        <b-nav vertical>
            <b-nav-item title="Our brands" :to="{ name: 'brands'}">Our brands
                <b-nav vertical>
                    <b-nav-item title="Il Lasagnista" :to="{ name: 'lasagnista'}">Il Lasagnista</b-nav-item>
                    <b-nav-item title="Explorer's Grill" :to="{ name: 'explorers-grill'}">Explorer's Grill</b-nav-item>
                    <b-nav-item title="Wingcognito" :to="{ name: 'wingcognito'}">Wingcognito</b-nav-item>
                    <b-nav-item title="The Alt Slice" :to="{ name: 'alt-slice'}">The Alt Slice</b-nav-item>
                </b-nav>
            </b-nav-item>
            <b-nav-item>
                <b-btn :to="{ name: 'order' }" variant="light" class="btn-double text-dark-blue px-5" pill size="lg" title="Order now">Order now</b-btn>
            </b-nav-item>
            <li class="my-2"></li>
            <b-nav-item title="What we do" :to="{ name: 'about'}">What we do</b-nav-item>
            <b-nav-item title="Our story" :to="{ name: 'story'}">Our story</b-nav-item>
            <b-nav-item title="Get in touch" :to="{ name: 'contact'}">Get in touch</b-nav-item>
            
            <b-nav-item>
                <b-btn block :to="{ name: 'help' }" variant="outline-light" pill size="lg" title="Order now">
                    <font-awesome-icon :icon="['fas','question-circle']" class="mr-2"></font-awesome-icon>
                    Help centre
                </b-btn>
            </b-nav-item>
        </b-nav>
    </b-collapse>
</template>
<script>
export default {
    name: "SideNav"
}
</script>