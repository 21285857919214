<template>
    <div>
        <div :class="wrapperClasses">
            <slot></slot>
        </div>
        <b-col cols="12" class="img-wrapper-col d-lg-none px-0">
            <slot></slot>
        </b-col>
    </div>
    

</template>
<script>
export default{
    name: "ImgWrapper",
    props: {
        left: {
            type: Boolean,
            default: false,
            required: false
        },
        right: {
            type: Boolean,
            default: false,
            required: false
        }
    },
    computed: {
        wrapperClasses(){
            let base = {}
            
            base[`img-wrapper`] = true
            
            if(this.left){
                base[`img-wrapper-left`] = true
            }
            if(this.right){
                base[`img-wrapper-right`] = true
            }
            
            return base
        }
    }
}
</script>