import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createI18n } from 'vue-i18n'
import messages from './locales/messages.js'
import { BootstrapVue } from 'bootstrap-vue'
import VueGtag from 'vue-gtag'
import "@/assets/sass/main.scss"
import Icons from "./plugins/Icons"

const app = createApp(App)
const i18n = createI18n({
  locale: navigator.language || 'en',//'en', // set locale
  fallbackLocale: 'en', // set fallback locale
  messages,
  // something vue-i18n options here ...
})

import brands from './data/brands.json'
app.prototype.$brands = brands;

app.use(i18n)
app.use(router)
app.use(BootstrapVue)
app.use(Icons)
app.use(VueGtag, {
    config: {
      id: 'G-V2JMKHDS2L'
    },
  },
  router
)


app.mount('#app')
