<template>
    <div>
        <main-header />
        <div class="page">
            <router-view v-slot="{ Component }">
                <transition name="slide-left" mode="out-in" appear>
                    <component :is="Component" />
                </transition>
            </router-view>
        </div> 
        <help-bug />
        <main-footer />
    </div>
</template>
<script>
import MainHeader from "@/components/MainHeader"
import MainFooter from "@/components/MainFooter"
import HelpBug from "@/components/HelpBug"
export default{
    components:{
        HelpBug,
        MainHeader,
        MainFooter
    }
}
</script>
<style>.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  transition-duration: 0.5s;
  transition-property: height, opacity, transform;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  overflow: hidden;
}

.slide-left-enter,
.slide-right-leave-active {
  opacity: 0;
  transform: translate(2em, 0);
}

.slide-left-leave-active,
.slide-right-enter {
  opacity: 0;
  transform: translate(-2em, 0);
}
</style>