<template>
    <b-navbar fixed="top" :class="navClass" type="dark" toggleable="true">
      <div class="main-nav-stripes"></div>
      <b-navbar-brand class="mx-auto" :to="{name:'home'}"><img src="@/assets/img/wordmark-foods.svg" class="logo" alt="Fresco Foods"/></b-navbar-brand>
      <b-navbar-toggle class="ml-auto" target="side-nav"></b-navbar-toggle>
      <side-nav />        
    </b-navbar>
</template>
<script>
import SideNav from "@/components/SideNav"
export default{
    data() {
      return {
        scrollPosition: null
      }
    },
    components: {
      SideNav
    },
    methods: {
      updateScroll() {
        this.scrollPosition = window.scrollY
      },

    },
    computed: {
      navClass: function(){
        return this.scrollPosition <= 60 ? "main-nav" : "main-nav stuck"
      }
    },

    mounted() {
      window.addEventListener('scroll', this.updateScroll);
    }
  }
</script>