export default {    
    en: {
        content: {
          hero_tagline: "Exceltour is an Italian-English tour operator creating integrated global travel experiences since 2015.",
          main_header: "Crafting global experiences that form lasting memories.",
          main_body: "Established in 2015, Exceltour provides international tourism management services to the education, corporate and travel sectors utilising selected global partners.",
          card_por: "POR & PON education packages",
          card_management: "Integrated tourism management",
          card_corporate: "Global corporate consultancy",
          website_by: "Website by Hallsy."
        },
        buttons: {
            home: "Home",
            our_work: "Our work",
            contact: "Contact",
            get_in_touch: "Get in touch"
        },
        form: {
            name: "Name",
            company: "Company or organisation",
            email: "Email address",
            message: "Message",
            phone: "Phone number",
            language: "Preferred language",
            send: "Send message",
            sending: "Sending...",
            sent: "Message sent!",
            sent_body: "",
            error: "There was a problem sending your message - please try again."
        }
      },
      it: {
        content: {
            hero_tagline: "Exceltour è un tour operator italo-inglese che crea esperienze di viaggio globali integrate dal 2015.",
            main_header: "Creiamo esperienze globali che formano ricordi duraturi.",
            main_body: "Fondata nel 2015, Exceltour fornisce servizi di gestione del turismo internazionale ai settori dell'istruzione, aziendale e dei viaggi utilizzando partner globali selezionati.",
            card_por: "Pacchetti educativi POR e PON",
            card_management: "Gestione integrata del turismo",
            card_corporate: "Consulenza aziendale globale",
            website_by: "Sito web di Hallsy."
          },
          buttons: {
              home: "Home",
              our_work: "Il nostro lavoro",
              contact: "Contatti",
              get_in_touch: "Contatti"
          },
          form: {
              name: "Nome",
              company: "Azienda o organizzazione",
              email: "Indirizzo e-mail",
              message: "Messaggio",
              phone: "Numero di telefono",
              language: "Lingua preferita",
              send: "Invia messaggio",
              sending: "Attendere...",
              sent: "Messaggio inviato",
              sent_body: "",
              error: "",
          }
      }
    }