<template>
    <footer class="footer">
        
        <b-container>
            <div class="footer-inner">
            <b-row>
                <b-col md="4" lg="6">
                    <router-link to="/"><img src="@/assets/img/wordmark-foods-white-through.svg" class="footer-logo" alt="Fresco logo"></router-link>
                    
                    <p>
                        <social-buttons />
                    </p>
                    <p><a href="https://ratings.food.gov.uk/business/en-GB/1602823/Fresco-Foods-London" title=""><img src="@/assets/img/fhrs5.svg" style="height: 6rem;" alt="Fresco Foods - Food Hygiene Rating 5"/></a></p>

                </b-col>
                <b-col md="8" lg="6">
                    <b-row>
                    <b-col md="6" lg="4">
                       <router-link title="Our brands" class="link-unstyled" :to="{ name: 'brands'}"><h4 class="footer-nav-header">Our brands</h4></router-link>
                        <b-nav class="footer-nav">
                            <b-nav-item title="Il Lasagnista" :to="{ name: 'lasagnista'}">Il Lasagnista</b-nav-item>
                            <b-nav-item title="Explorer's Grill" :to="{ name: 'explorers-grill'}">Explorer's Grill</b-nav-item>
                            <b-nav-item title="Wingcognito" :to="{ name: 'wingcognito'}">Wingcognito</b-nav-item>
                            <b-nav-item title="The Alt Slice" :to="{ name: 'alt-slice'}">The Alt Slice</b-nav-item>
                        </b-nav>
                    </b-col>
                    <b-col md="6" lg="4">
                        <h4 class="footer-nav-header" title="About us" :to="{ name: 'about'}">About us</h4>
                        <b-nav class="footer-nav">
                            <b-nav-item title="What we do" :to="{ name: 'about'}">What we do</b-nav-item>
                            <b-nav-item title="Our story" :to="{ name: 'story'}">Our story</b-nav-item>
                            <!--b-nav-item title="Investors" :to="{ name: 'investors'}">Investors</b-nav-item-->
                        </b-nav>
                    </b-col>
                    <b-col md="6" lg="4">
                        <h4 title="Customers" class="footer-nav-header">Customers</h4>
                        <b-nav class="footer-nav">
                            <b-nav-item title="Get in touch" :to="{ name: 'contact'}">Get in touch</b-nav-item>
                            <b-nav-item title="Help Centre" :to="{name: 'help'}">Help Centre</b-nav-item>
                            <b-nav-item title="Privacy Policy" :to="{name: 'privacy'}">Privacy Policy</b-nav-item>
                        </b-nav>
                    </b-col>
                    </b-row>
                </b-col>
                
            </b-row>
            </div>
            
            <p class="legal">
                <a href="https://hallsyservices.com" class="h" title="Website and branding by Hallsy Services" v-b-tooltip.topleft></a>
                Fresco Foods and Fresco are trading names of Beeat Ltd, a registered company in England &amp; Wales no. 14444867. <router-link :to="{name:'privacy'}" title="Privacy policy">Privacy Policy</router-link>
            </p>
        </b-container>
    </footer>
</template>
<script>
import SocialButtons from "@/components/SocialButtons"
export default{
    name: "MainFooter",
    components: {
        SocialButtons
    }
}</script>