import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '../views/HomePage'
import MainLayout from '../layouts/MainLayout'

const routes = [
  {
    path: '/',
    name: 'default',
    component: MainLayout,
    children: [
      {
        path: '',
        name: 'home',
        component: HomePage
      },
      {
        path: 'about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/AboutPage')
      },
      {
        path: 'order',
        name: 'order',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/OrderPage')
      },
      {
        path: 'story',
        name: 'story',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/StoryPage')
      },
      {
        path: 'investors',
        name: 'investors',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "investors" */ '../views/InvestorsPage')
      },
      {
        path: 'privacy',
        name: 'privacy',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "privacy" */ '../views/PrivacyPage')
      },
      {
        path: 'contact',
        name: 'contact',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "contact" */ '../views/ContactPage')
      },
      {
        path: 'help',
        name: 'help',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "help" */ '../views/HelpPage')
      },
      {
        path: 'brands',
        name: 'brands',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "brands" */ '../views/BrandsPage'),
      },
      {
        path: 'brands/explorers-grill',
        name: 'explorers-grill',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "explorersgrill" */ '../views/ExplorersGrillPage')
      },
      {
        path: 'brands/il-lasagnista',
        name: 'lasagnista',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "lasagnista" */ '../views/LasagnistaPage')
      },
      {
        path: 'brands/wingcognito',
        name: 'wingcognito',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "wingcognito" */ '../views/WingcognitoPage')
      },
      {
        path: 'brands/alt-slice',
        name: 'alt-slice',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "wingcognito" */ '../views/AltSlicePage')
      },
      
      
      {
        path: 'app',
        name: 'app',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "order" */ '../views/AppPage')
      },
    ]
},
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition
    }
    if (to.hash) {
      return { selector: to.hash }
    }
    return { top: 0 }
  }
})

export default router
