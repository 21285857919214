<template>
    <section :class="panelClasses">
        <div class="video-wrapper" v-if="video">
            <slot name="video"></slot>            
        </div>
        <slot name="base"></slot>
        <b-container>
            <b-row align-v="center">
                <slot name="default">
                    <b-col>Default content</b-col>
                </slot>
            </b-row>
            <slot name="end"></slot>
        </b-container>
    </section>
</template>
<script>
export default {
    name: 'ContentPanel',
    props:{
        bgVariant:{
            type: String,
            default: "",
            required: false
        },
        name: {
            type: String,
            default: "",
            required: false
        },
        dark: {
            type: Boolean,
            default: false,
            required: false
        },
        slim: {
            type: Boolean,
            default: false,
            required: false
        },
        video: {
            type: Boolean,
            default: false,
            required: false
        },
        /*videoName: {
            type: String,
            default: "",
            required: false
        }*/
    },
    computed:{
        panelClasses(){
            let base = {}
            
            base[`panel`] = true
            base[`panel-${this.name}`] = true
            base[`panel-dark`] = this.dark
            
            if(this.slim){
                base[`panel-slim`] = true
            }
            
            if(this.bgVariant != ""){
                base[`bg-${this.bgVariant}`] = true
            }

            if(this.video){
                base[`panel-video`] = true
            }
            return base
        }
    }
}
</script>