<template>
    <section :class="heroClasses" :style="heroStyle">
        <div class="video-wrapper" v-if="video">
            <video class="cover" poster="@/assets/videos/hero-v3.jpg" autoplay loop muted>
                <source src="@/assets/videos/hero-v3.mp4" type="video/mp4">
                <source src="@/assets/videos/hero-v3.webm" type="video/webm">
            </video>
        </div>
        <b-container class="main" :class="{'text-center': this.center}">
            <b-row>
                
                <b-col v-if="!noCols">
                    <slot name="default">Default content</slot>
                </b-col>
                <slot v-else name="default"></slot>
            </b-row>
        </b-container>
        <b-container class="bottom">
            <slot name="bottom"></slot>
        </b-container>
    </section>
</template>
<script>
export default{
    name: "HeroPanel",
    props: {
        bgSrc:{
            type: String,
            default: "",
            required: false
        },
        size: {
            type: String,
            default: "",
            required: false
        },
        name: {
            type: String,
            default: "default",
            required: false
        },
        bgVariant:{
            type: String,
            default: "",
            required: false
        },
        dark:{
            type: Boolean,
            default: false,
            required: false
        },
        wave:{
            type: Boolean,
            default: false,
            required: false
        },
        style: {
            type: String,
            default: "",
            required: false
        },
        noCols: {
            type: Boolean,
            default: false,
            required: false
        },
        center: {
            type: Boolean,
            required: false,
            default: false
        },
        slim: {
            type: Boolean,
            required: false,
            default: false
        },
        video: {
            type: Boolean,
            required: false,
            default: false
        }
        

    },
    computed:{
        heroStyle(){
            let style = ""
            if(this.bgSrc != ""){
                style = `${style} background-image: url('${this.bgSrc}');`
            }
            if(this.style != ""){
                style = `${style} ${this.style}`
            }
            return style
        },
        heroClasses(){
            let base = {}
            
            base[`hero`] = true
            base[`hero-${this.name}`] = true
            
            
            if(this.size != ""){
                base[`hero-${this.size}`] = true
            }

            if(this.bgVariant != ""){
                base[`bg-${this.bgVariant}`] = true
            }
            
            if(this.slim){
                base[`hero-slim`] = true
            }

            if(this.video){
                base[`hero-video`] = true
            }
            
            base[`hero-dark`] = this.dark
            base[`hero-wave`] = this.wave

            return base


        }
    }
}</script>