<template>
    <div class="mb-2">
        <b-btn variant="outline-light" class="btn-social mr-2" size="lg" title="@FoodsByFresco on Instagram" v-b-tooltip href="https://instagram.com/foodsbyfresco">
            <font-awesome-icon :icon="['fab','instagram']" alt="Instagram"/>
        </b-btn>
        <b-btn variant="outline-light" class="btn-social mr-2" size="lg" title="@eatfres.co on TokTik" v-b-tooltip href="https://tiktok.com/@eatfres.co">
            <font-awesome-icon :icon="['fab','tiktok']" alt="Toktik"/>
        </b-btn>
        <b-btn variant="outline-light" class="btn-social mr-2" size="lg" title="Fresco Foods on Facebook" v-b-tooltip href="https://facebook.com/byfrescofoods">
            <font-awesome-icon :icon="['fab','facebook']" alt="Facebook"/>
        </b-btn>
        <b-btn variant="outline-light" class="btn-social" size="lg" title="@eatfres_co on Twitter" v-b-tooltip href="https://twitter.com/eatfres_co">
            <font-awesome-icon :icon="['fab','twitter']" alt="Twitter"/>
        </b-btn>
    </div>
</template>